import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';


export default () => {
    const [isScrolled, setIsScrolled] = useState(false);

    // Add scroll listener to change header style on scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`landing-header ${isScrolled ? 'scrolled' : ''}`}>
            <Navbar expand="lg" variant="dark" className="py-3">
                <Container fluid>
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            <img
                                src="/images/cam-logo.png"
                                alt="logo"
                                className="img-logo"
                                width={250}
                            />
                        </Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="landing-navbar-nav" />
                    <Navbar.Collapse id="landing-navbar-nav">
                        <Nav className="ms-auto">
                            <LinkContainer to="/products">
                                <Nav.Link className="landing-nav-link">Products</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/login">
                                <Nav.Link className="landing-nav-link">Sign In</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
