import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import CommonFooter from "./components/CommonFooter";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import LandingPage from "./screens/LandingPage";
import ContactScreen from "./screens/ContactScreen";
import Settings from "./screens/StoreSettings";
import OrdersList from "./screens/OrdersListScreen";
import AdminOrderScreen from "./screens/AdminOrderScreen";

// Layout component that wraps content with header, container, and footer
const DefaultLayout = ({ children }) => (
  <>
    <Header />
    <div className="min-vh-100 d-flex flex-column">
      <Container>
        <main className="p-3 flex-grow-1">
          <div style={{ marginBottom: "100px" }} />
          {children}
        </main>
      </Container>
      <CommonFooter />
    </div>
  </>
);

// Component to handle layout selection based on route
const LayoutHandler = ({ children }) => {
  const location = useLocation();
  
  // Add paths that should use blank layout (no header/footer)
  const blankLayoutPaths = ['/', '/landing'];
  
  return blankLayoutPaths.includes(location.pathname) ? (
    children
  ) : (
    <DefaultLayout>{children}</DefaultLayout>
  );
};

const App = () => {
  return (
    <Router>
      <LayoutHandler>
        <Routes>
          {/* Landing page route */}
          <Route path="/" element={<LandingPage />} exact />
          
          {/* Product routes */}
          <Route path="/products" element={<HomeScreen />} exact />
          <Route path="/product/:id" element={<ProductScreen />} />
          
          {/* Cart routes */}
          <Route path="/cart/:id" element={<CartScreen />} />
          <Route path="/cart/" element={<CartScreen />} />
          
          {/* Auth routes */}
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          
          {/* Checkout routes */}
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          
          {/* Order routes */}
          <Route path="/orders/:id" element={<OrderScreen />} />
          
          {/* Admin routes */}
          <Route path="/admin/orderlist/:id" element={<AdminOrderScreen />} />
          <Route path="/admin/orderlist" element={<OrdersList />} />
          <Route path="/admin/userslist" element={<UserListScreen />} />
          <Route path="/admin/productlist" element={<ProductListScreen />} />
          <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
          <Route path="/admin/product/:id/edit" element={<ProductEditScreen />} />
          <Route path="/admin/settings" element={<Settings />} />
          
          {/* Other routes */}
          <Route path="/contact" element={<ContactScreen />} />
        </Routes>
      </LayoutHandler>
    </Router>
  );
};

export default App;